import React, {Component} from 'react';
import {Accordion, Divider, Icon, Image, Menu,} from 'semantic-ui-react';
import {Link} from "gatsby";
import styles from "./mainnavigation.module.css";
import invertedLogo from "./assets/dsk-inverted2.svg";

class SideBarNav extends Component {
    state = {activeIndex: -1};

    handleClick = (e, titleProps) => {
        const {index} = titleProps;
        const {activeIndex} = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({activeIndex: newIndex})
    };

    render() {
        const {activeIndex} = this.state;
        return (<>
                <Link to="/" activeClassName="active"><Image src={invertedLogo} className={styles.invertedLogo}/></Link>
                <Divider/>
                <Link to="/" activeClassName="active" className={styles.secondLevelLink}><Menu.Item
                    className={styles.secondMenuItem}><Icon className={styles.sideBarIcon}
                                                            name={'home'}/>Start</Menu.Item></Link>
                <Link to="/kontakt/" activeClassName="active"><Menu.Item className={styles.secondMenuItem}><Icon
                    className={styles.sideBarIcon} name={'address card'}/>Kontakt</Menu.Item></Link>
                <Divider/>

                <Accordion>
                    <Accordion.Title active={activeIndex === 1} index={1} onClick={this.handleClick}
                                     className={styles.dropDownTitle}>
                        <Icon inverted name='dropdown' className={styles.dropDown}/>
                        <Link to="/was-wir-tun/" activeClassName="active" className={styles.secondLevelLink}><Menu.Item
                            className={styles.accordionMenuItem}><Icon className={styles.sideBarIcon}
                                                                       name={'plus square'}/>Was wir
                            tun</Menu.Item></Link>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 1} className={styles.dropDownContent}>
                        <Link to="/was-wir-tun/#projekte" activeClassName="active"
                              className={styles.secondLevelLink}><Menu.Item>Projekte</Menu.Item></Link>
                        <Link to="/was-wir-tun/#hilfe" activeClassName="active"
                              className={styles.secondLevelLink}><Menu.Item>Hilfsmaßnahmen</Menu.Item></Link>
                        <Link to="/was-wir-tun/besonderhaut/" activeClassName="active"
                              className={styles.secondLevelLink}><Menu.Item>BesonderHaut</Menu.Item></Link>
                        <Link to="/was-wir-tun/foerderprogramm/" activeClassName="active"
                              className={styles.secondLevelLink}><Menu.Item>Förderprogramm</Menu.Item></Link>
                    </Accordion.Content>
                </Accordion>
                        <Link to="/unterstuetzen/" activeClassName="active"
                              className={styles.secondLevelLink}><Menu.Item className={styles.secondMenuItem}><Icon
                            className={styles.sideBarIcon} name={'heart'}/>Unterstützen</Menu.Item></Link>
                <Link to="/antraege/" activeClassName="active"
                      className={styles.secondLevelLink}><Menu.Item className={styles.secondMenuItem}><Icon
                    className={styles.sideBarIcon} name={'clipboard'}/>Anträge</Menu.Item></Link>
                <Accordion>
                    <Accordion.Title active={activeIndex === 2} index={2} onClick={this.handleClick}
                                     className={styles.dropDownTitle}>
                        <Icon inverted name='dropdown' className={styles.dropDown}/>
                        <Link to="/ueber-uns/" activeClassName="active" className={styles.secondLevelLink}><Menu.Item
                            className={styles.accordionMenuItem}><Icon className={styles.sideBarIcon} name={'users'}/>Über
                            uns</Menu.Item></Link>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 2} className={styles.dropDownContent}>
                        <Link to="/ueber-uns/schirmherr/" activeClassName="active"
                              className={styles.secondLevelLink}><Menu.Item>Schirmherr</Menu.Item></Link>
                        <Link to="/ueber-uns/vorstand/" activeClassName="active"
                              className={styles.secondLevelLink}><Menu.Item>Vorstand</Menu.Item></Link>
                        <Link to="/ueber-uns/kuratorium/" activeClassName="active"
                              className={styles.secondLevelLink}><Menu.Item>Kuratorium</Menu.Item></Link>
                        <Link to="/ueber-uns/beirat/" activeClassName="active"
                              className={styles.secondLevelLink}><Menu.Item>Wissenschaftlicher Beirat</Menu.Item></Link>
                        <Link to="/ueber-uns/komitee/" activeClassName="active"
                              className={styles.secondLevelLink}><Menu.Item>Preisvergabekomitee</Menu.Item></Link>
                        {/*<Link to="/ueber-uns/geschaeftsstelle/" activeClassName="active"*/}
                              {/*className={styles.secondLevelLink}><Menu.Item>Geschäftsstelle</Menu.Item></Link>*/}
                        <Link to="/ueber-uns/grusswort-vorstand/" activeClassName="active"
                              className={styles.secondLevelLink}><Menu.Item>Grußwort
                            Vorstandsvorstizende</Menu.Item></Link>
                        <Link to="/ueber-uns/grusswort-schirmherr/" activeClassName="active"
                              className={styles.secondLevelLink}><Menu.Item>Grußwort Schirmherr</Menu.Item></Link>
                        <Link to="/ueber-uns/grusswort-ddg/" activeClassName="active"
                              className={styles.secondLevelLink}><Menu.Item>Grußwort DDG</Menu.Item></Link>
                        <Link to="/ueber-uns/grusswort-bvdd/" activeClassName="active"
                              className={styles.secondLevelLink}><Menu.Item>Grußwort BVDD</Menu.Item></Link>
                        <Link to="/ueber-uns/grusswort-apd/" activeClassName="active"
                              className={styles.secondLevelLink}><Menu.Item>Grußwort APD</Menu.Item></Link>
                        <Link to="/ueber-uns/grusswort-nipd/" activeClassName="active"
                              className={styles.secondLevelLink}><Menu.Item>Grußwort NipD</Menu.Item></Link>
                    </Accordion.Content>
                </Accordion>
                <Link to="/news/" activeClassName="active" className={styles.secondLevelLink}><Menu.Item
                    className={styles.secondMenuItem}><Icon className={styles.sideBarIcon}
                                                            name={'newspaper'}/>News</Menu.Item></Link>

                <Divider/>
                <Link to="/sponsoren/" activeClassName="active" className={styles.secondLevelLink}><Menu.Item
                    className={styles.secondMenuItem}><Icon className={styles.sideBarIcon} name={'building'}/>Sponsoren
                    & Förderer</Menu.Item></Link>
                <Link to="/freunde/" activeClassName="active" className={styles.secondLevelLink}><Menu.Item
                    className={styles.secondMenuItem}><Icon className={styles.sideBarIcon}
                                                            name={'thumbs up'}/>Freunde</Menu.Item></Link>
                <Accordion>
                    <Accordion.Title active={activeIndex === 3} index={3} onClick={this.handleClick}
                                     className={styles.dropDownTitle}>
                        <Icon inverted name='dropdown' className={styles.dropDown}/>
                        <Link to="/stiftung/" activeClassName="active" className={styles.secondLevelLink}><Menu.Item
                            className={styles.accordionMenuItem}><Icon className={styles.sideBarIcon} name={'circle'}/>Stiftung</Menu.Item></Link>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 3} className={styles.dropDownContent}>
                        <Link to="/stiftung/grundsaetze/" activeClassName="active"
                              className={styles.secondLevelLink}><Menu.Item>Grundsätze</Menu.Item></Link>
                        <Link to="/stiftung/principles/" activeClassName="active"
                              className={styles.secondLevelLink}><Menu.Item>Prinzipien</Menu.Item></Link>
                        <Link to="/stiftung/transparenz/" activeClassName="active"
                              className={styles.secondLevelLink}><Menu.Item>Transparenz</Menu.Item></Link>
                        <Link to="/stiftung/anlagerichtlinien/" activeClassName="active"
                              className={styles.secondLevelLink}><Menu.Item>Anlagerichtlinien</Menu.Item></Link>
                    </Accordion.Content>
                </Accordion>
                <Link to="/video/" activeClassName="active" className={styles.secondLevelLink}><Menu.Item
                    className={styles.secondMenuItem}><Icon className={styles.sideBarIcon}
                                                            name={'film'}/>Video</Menu.Item></Link>
            </>

        );
    }
}


export default SideBarNav;