import React from 'react';
import {graphql, StaticQuery} from "gatsby";
import SearchComponent from "./SearchComponent";

const SearchQuery = () => (
    <StaticQuery
        query={graphql`
            query SearchIndexQuery {
                siteSearchIndex {
                    index
                }
            }
        `}
        render={data => (
                <SearchComponent searchIndex={data.siteSearchIndex.index}/>
        )}
    />
);

export default SearchQuery;