import React, {Component} from "react";
import {Index} from "elasticlunr";
import {navigate} from "@reach/router";
import {Search} from "semantic-ui-react";
import _ from 'lodash';
import styles from "./search.module.css";
import DesktopContainer from "../../container/DesktopContainer";
import MobileContainer from "../../container/MobileContainer";

// Search component
export default class SearchComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query: ``,
            results: []
        };
    }

    componentWillMount() {
        this.resetComponent()
    }

    resetComponent = () => this.setState({isLoading: false, results: [], value: ''});

    // handleResultSelect = (e, { result }) => this.setState({ value: result.title })
    handleResultSelect = (e, {result}) => navigate(buildPath(result.path, result.type));

    handleSearchChange = (e, {value}) => {
        this.setState({isLoading: true, value});

        setTimeout(() => {
            if (this.state.value.length < 1) return this.resetComponent();

            // const re = new RegExp(_.escapeRegExp(this.state.value), 'i')
            // const isMatch = result => re.test(result.title)

            this.setState({
                isLoading: false,
                // results: _.filter(source, isMatch),
                results: this.state.results,
                query: this.state.value
            });
            this.search();
        }, 300)
    };

    render() {
        const {isLoading, results} = this.state;
        return (
            <div>
                {/*<input*/}
                {/*type="text"*/}
                {/*value={this.state.query}*/}
                {/*onChange={this.search}*/}
                {/*/>*/}
                {/*<ul>*/}
                {/*{this.state.results.map(page => (*/}
                {/*<li key={page.id}>*/}
                {/*/!*<Link to={"/" + page.path}>{page.title}</Link>*!/*/}
                {/*<Link to={buildPath(page.path, page.type)}>{page.title}</Link>*/}
                {/*/!*{": " + page.tags.join(`,`)}*!/*/}
                {/*</li>*/}
                {/*))}*/}
                {/*</ul>*/}
                <DesktopContainer className={styles.desktop}>
                    <Search
                        loading={isLoading}
                        onResultSelect={this.handleResultSelect}
                        onSearchChange={_.debounce(this.search, 500, {leading: true})}
                        results={results}
                        value={this.state.query}
                        {...this.props}
                        showNoResults={false}
                        aligned={'right'}
                        className={styles.search}
                    />
                </DesktopContainer>
                <MobileContainer className={styles.mobile}>
                    <Search
                        loading={isLoading}
                        onResultSelect={this.handleResultSelect}
                        onSearchChange={_.debounce(this.search, 500, {leading: true})}
                        results={results}
                        value={this.state.query}
                        {...this.props}
                        showNoResults={false}
                        aligned={'right'}
                        className={styles.search}
                    />
                </MobileContainer>
            </div>
        );
    }

    getOrCreateIndex = () =>
        this.index
            ? this.index
            : // Create an elastic lunr index and hydrate with graphql query results
            Index.load(this.props.searchIndex);

    search = evt => {
        const query = evt.target.value;
        this.index = this.getOrCreateIndex();
        this.setState({
            query,
            // Query the index with search string to get an [] of IDs
            results: this.index
                .search(query, {})
                // Map over each ID and return the full document
                .map(({ref}) => this.index.documentStore.getDoc(ref))
        });
    };
}

function buildPath(path, type) {
    if (!!path) {
        return path;
    }
    if (type === "news") {
        return "/news/";
    }
    if (type === "friends") {
        return "/freunde/";
    }
    if (type === "sponsor" || type === "supporter") {
        return "/sponsoren/";
    }
    if (type === "projects" || type === "aid") {
        return "/was-wir-tun/";
    }
    if (type === "support-page" || type === "support-entry-possibility" || type === "support-possibility") {
        return "/unterstuetzen/";
    }
    if (type === "about-us" || type === "person") {
        return "/ueber-uns/";
    }
    if (type === "office") {
        return "/ueber-uns/geschaeftsstelle/";
    }
    if (type === "bank-data") {
        return "/spendenkonto/";
    }
    if (type === "contact" || type === "contact-data") {
        return "/kontakt/";
    }
    return "/";
}