import React from 'react';
import {Container, Divider, Grid, Header, Icon, Image, List, Segment,} from 'semantic-ui-react';
import {Link} from "gatsby";
import bvds from "../../assets/bvds.svg";
import styles from "./footer.module.css";
import DesktopContainer from "../../container/DesktopContainer";
import MobileTabletContainer from "../../container/MobileTabletContainer";

const Footer = () => (
    <div className={"footer"}>
        <MobileTabletContainer className={styles.mobile}>
            <Segment className={styles.footer} >
                <Container>
                    <div className={styles.container}>
                        <Grid inverted stackable>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <Header as='h4' content='Mitglied im'/>
                                    <Image href="http://www.stiftungen.org" as="a" size="medium" centered src={bvds}/>
                                </Grid.Column>
                                <Grid.Column>
                                    <Header as='h4'>
                                        Ein Herz für hautkranke Kinder
                                    </Header>
                                    <p><Icon name='copyright outline'/>2019 Deutsche Stiftung Kinderdermatologie</p>
                                    <p>Rechtsfähige Stiftung des bürgerlichen Rechts mit Sitz in München seit 16. Dezember 2010.</p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Divider section/>
                        <Container textAlign={"center"} style={{margin: '2em 0 0 0'}}>
                            <List link className={styles.linkList}>
                                <List.Item className={styles.list}>
                                    <Link to="/spendenkonto/">Spendenkonto</Link>
                                </List.Item>
                                <List.Item className={styles.list}>
                                    <Link to="/kontakt/">Kontakt</Link>
                                </List.Item>
                                <List.Item className={styles.list}>
                                    <Link to="/impressum/">Impressum</Link>
                                </List.Item>
                                <List.Item className={styles.list}>
                                    <Link to="/datenschutz/">Datenschutzhinweis</Link>
                                </List.Item>
                            </List>
                        </Container>
                    </div>
                </Container>
            </Segment>
        </MobileTabletContainer>
        <DesktopContainer className={styles.desktop}>
            <Segment className={styles.footer} inverted>
            <Container fluid>
                    <div className={styles.container}>
                    <Grid inverted stackable>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Header as='h4' content='Mitglied im' className={styles.header}/>
                                <Image href="http://www.stiftungen.org" as="a" size="medium" centered src={bvds}/>
                            </Grid.Column>
                            <Grid.Column>
                                <Header as='h4'className={styles.header} >
                                    Ein Herz für hautkranke Kinder
                                </Header>
                                <p><Icon name='copyright outline'/>2022 Deutsche Stiftung Kinderdermatologie</p>
                                <p>Rechtsfähige Stiftung des bürgerlichen Rechts mit Sitz in München seit 16. Dezember 2010.</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Divider section/>
                    <Container textAlign={"center"} style={{margin: '2em 0 0 0'}}>
                        <List horizontal divided link className={styles.linkList}>
                            <List.Item>
                                <Link to="/spendenkonto/">Spendenkonto</Link>
                            </List.Item>
                            <List.Item>
                                <Link to="/kontakt/">Kontakt</Link>
                            </List.Item>
                            <List.Item>
                                <Link to="/impressum/">Impressum</Link>
                            </List.Item>
                            <List.Item>
                                <Link to="/datenschutz/">Datenschutzhinweis</Link>
                            </List.Item>
                        </List>
                    </Container>
                        </div>
            </Container>
            </Segment>
        </DesktopContainer>

    </div>
);

export default Footer;