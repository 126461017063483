import React, {Component} from 'react';
import styles from "../templates/default.module.css";
import cx from 'classnames';
import MediaQuery from 'react-responsive';

class MobileContainer extends Component {
    render() {
        let className = '';

        if (this.props.className !== undefined) {
            className = this.props.className;
        }

        return (
            <div className={cx(className, "mobile")}>
                <MediaQuery maxWidth={767} className={cx(styles.mobile, 'mobile')}>
                    {this.props.children}
                </MediaQuery>
            </div>
        );
    }
}

export default MobileContainer;