import React from "react";
import styles from "./primarybutton.module.css";
import {Button} from "semantic-ui-react";
import DesktopContainer from "../../../container/DesktopContainer";
import MobileContainer from "../../../container/MobileContainer";
import TabletContainer from "../../../container/TabletContainer";

const PrimaryButton = ({link, children}) => (
    <>
        <MobileContainer className={styles.mobile}>
            <Button as={'a'} href={link} className={styles.button}>{children}</Button>
        </MobileContainer>
        <TabletContainer className={styles.mobile}>
            <Button as={'a'} href={link} className={styles.button}>{children}</Button>
        </TabletContainer>
        <DesktopContainer className={styles.desktop}>
            <Button as={'a'} href={link} className={styles.button}>{children}</Button>
        </DesktopContainer>
    </>

);


export default PrimaryButton;