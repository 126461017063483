import React, {Component} from 'react';
import styles from "../templates/default.module.css";
import cx from 'classnames';
import MediaQuery from "react-responsive";

class TabletContainer extends Component {
    render() {
        let className = '';

        if (this.props.className !== undefined) {
            className = this.props.className;
        }

        return (
            <div className={cx(className, "mobile")}>
                <MediaQuery minWidth={768} maxWidth={1023} className={cx(styles.mobile, 'tablet')}>
                    {this.props.children}
                </MediaQuery>
            </div>
        );
    }
}

export default TabletContainer;