import React from 'react';
import {Grid, Header} from "semantic-ui-react";
import styles from "./fullLogo.module.css";
import DesktopContainer from "../../container/DesktopContainer";
import {graphql, Link, StaticQuery} from "gatsby";
import MobileContainer from "../../container/MobileContainer";
import TabletContainer from "../../container/TabletContainer";
import Img from "../utils/Img";


const LogoFull = () => (
        <>
            <StaticQuery query={logoQuery}
                         render={data => (
                             <>
                                 <MobileContainer className={styles.mobile}>
                                     <div className={styles.container}>

                                                 <Link to="/"><div><Img id={'dskLogo'} className={styles.image} critical
                                                                   fadeIn={false} fluid={data.file.childImageSharp.fluid}/></div></Link>

                                                 <Header as='h1' className={styles.header}>
                                                     <Grid.Row>
                                                         Deutsche Stiftung
                                                     </Grid.Row>
                                                     <Grid.Row>
                                                         <span>Kinderdermatologie</span>
                                                     </Grid.Row>
                                                 </Header>
                                                 <div className={styles.sub}>Ein Herz für hautkranke Kinder</div>
                                     </div>
                                 </MobileContainer>
                                 <TabletContainer className={styles.mobile}>
                                     <div className={styles.container}>
                                         <Link to="/"><Img id={'dskLogo'} className={styles.image} critical
                                                           fadeIn={false} fluid={data.file.childImageSharp.fluid}/></Link>
                                         <Header as='h1' className={styles.header}>
                                             <Grid.Row>
                                                 Deutsche Stiftung
                                             </Grid.Row>
                                             <Grid.Row>
                                                 <span>Kinderdermatologie</span>
                                             </Grid.Row>
                                         </Header>
                                         <div className={styles.sub}>Ein Herz für hautkranke Kinder</div>
                                     </div>
                                 </TabletContainer>
                                 <DesktopContainer className={styles.desktop}>
                                     <Grid>
                                         <Grid.Row columns={2}>
                                             <Grid.Column width={4} className={styles.logoCol}>
                                                 <Link to="/">
                                                     <Img id={'dskLogo'} className={styles.image} critical
                                                                   fadeIn={false} fluid={data.file.childImageSharp.fluid}/></Link>
                                             </Grid.Column>
                                             <Grid.Column width={10} verticalAlign={'middle'} className={styles.nameColumn}>
                                                 <Header as='h1' className={styles.header}>
                                                     <Grid.Row>
                                                         Deutsche Stiftung
                                                     </Grid.Row>
                                                     <Grid.Row>
                                                         <span>Kinderdermatologie</span>
                                                     </Grid.Row>
                                                 </Header>
                                             </Grid.Column>
                                         </Grid.Row>
                                         <Grid.Row column={1}>
                                             <Grid.Column>
                                                 <div className={styles.sub}>Ein Herz für hautkranke Kinder</div>
                                             </Grid.Column>
                                         </Grid.Row>
                                     </Grid>
                                 </DesktopContainer>
                             </>
                         )}
            />
        </>
    )
;

export default LogoFull;

const logoQuery = graphql`
  query logoQuery {
    file(relativePath: { eq: "logo1.png" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;