import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Container, Dropdown, Grid, Icon, Menu, Rail, Sidebar,} from 'semantic-ui-react';
import {Link} from "gatsby";
import styles from "./mainnavigation.module.css";
import LogoFull from "../logo/LogoFull";
import SearchQuery from "../search/SearchQuery";
import DesktopContainer from "../../container/DesktopContainer";
import cx from "classnames";
import SideBarNav from "./SideBarNav";
import PrimaryButton from "../utils/buttons/PrimaryButton";
import FriendlyButton from "../utils/buttons/FriendlyButton";
import MobileContainer from "../../container/MobileContainer";
import TabletContainer from "../../container/TabletContainer";
import MediaQuery from "react-responsive";

class MainNavigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sidebarOpened: false,
            page: this.props.page
        };

        this.handleToggle = this.handleToggle.bind(this);
    }

    handleToggle = () => this.setState({sidebarOpened: !this.state.sidebarOpened});

    render() {

        const {sidebarOpened, page} = this.state;
        const newsPartially = (page === 'news-article' ? styles.activeLink : '');
        return (
            <div>
                <MobileContainer className={styles.mobile}>
                    <Rail position='left'>
                        <Sidebar as={Menu} animation='overlay' inverted vertical visible={sidebarOpened}
                                 className={styles.sideBar}>
                            <Icon onClick={this.handleToggle} name='arrow left' className={styles.sideBarToggle}/>
                            <SideBarNav page={page}/>
                        </Sidebar>
                    </Rail>
                    <Container className={styles.menu}>
                                <div className={styles.searchBurger}>
                                    <MediaQuery maxWidth={360} className={styles.smallBurger}>
                                    <div onClick={this.handleToggle} className={styles.mainMenuItem}>
                                        <Button className={styles.menuButton} icon labelPosition={'left'}><Icon name='sidebar'/></Button>
                                    </div>
                                    </MediaQuery>
                                    <MediaQuery minWidth={361}>
                                        <div onClick={this.handleToggle} className={styles.mainMenuItem}>
                                            <Button className={cx(styles.m361, styles.menuButton)} icon labelPosition={'left'}><Icon name='sidebar'/>Menü</Button>
                                        </div>
                                    </MediaQuery>
                                </div>
                                <div className={styles.specialButtons}>
                                    <div className={styles.friendButton}>
                                        <FriendlyButton link={'/freunde/freund-werden/'}>
                                            Freund werden
                                        </FriendlyButton>
                                    </div>
                                    <div className={styles.donationButton}>
                                        <PrimaryButton link={'/spendenkonto/'}>Spenden</PrimaryButton>
                                    </div>
                                </div>

                        {/*<SearchQuery/>*/}

                        <LogoFull/>
                    </Container>
                </MobileContainer>
                <TabletContainer className={styles.mobile}>
                    <Rail position='left'>
                        <Sidebar as={Menu} animation='overlay' inverted vertical visible={sidebarOpened}
                                 className={styles.sideBar}>
                            <Icon onClick={this.handleToggle} name='arrow left' className={styles.sideBarToggle}/>
                            <SideBarNav page={page}/>
                        </Sidebar>
                    </Rail>
                    <Container className={styles.menu}>
                        <div className={styles.searchBurger}>
                            <div onClick={this.handleToggle} className={styles.mainMenuItem}>
                                <Button className={styles.menuButton} icon labelPosition={'left'} style={{width: "100px"}}><Icon name='sidebar'/><span style={{position: "relative", left: "10px"}}>Menü</span></Button>
                            </div>
                        </div>
                        <div className={styles.specialButtons}>
                            <div className={styles.friendButton}>
                                <FriendlyButton link={'/freunde/freund-werden/'}>
                                    Freund werden
                                </FriendlyButton>
                            </div>
                            <div className={styles.donationButton}>
                                <PrimaryButton link={'/spendenkonto/'}>Spenden</PrimaryButton>
                            </div>
                        </div>

                        {/*<SearchQuery/>*/}

                        <LogoFull/>
                    </Container>
                </TabletContainer>
                <DesktopContainer className={styles.desktop}>
                    <Container fluid className={styles.menu}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={3}>
                                    <LogoFull/>
                                </Grid.Column>
                                <Grid.Column className={cx("menu-extra", styles.rightMenu)} floated={'right'}>
                                    <Grid.Row>
                                        <SearchQuery/>
                                    </Grid.Row>
                                    <Grid.Row verticalAlign={'bottom'} className={styles.specialButtons}>
                                        <Grid.Column>
                                            <FriendlyButton link={'/freunde/freund-werden/'}>
                                                Freund werden
                                            </FriendlyButton>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <div className={styles.donationButton}>
                                            <PrimaryButton link={'/spendenkonto/'}>
                                                Spenden
                                            </PrimaryButton>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className={cx("navRow", styles.navRow)}>
                                <Menu
                                    className={styles.mainMenu}
                                >
                                    <Menu.Item className={styles.mainMenuItem}><Link to="/"
                                                                                     activeClassName={styles.activeLink}
                                                                                     className={styles.link}>Start</Link></Menu.Item>
                                    <Menu.Item className={styles.mainMenuItem}><Link to="/kontakt/"
                                                                                     activeClassName={styles.activeLink}
                                                                                     className={styles.link}>Kontakt</Link></Menu.Item>
                                    <Menu.Item className={styles.mainMenuItem}>
                                        <Dropdown text={'Was wir tun'} className={styles.link} simple>
                                            <Dropdown.Menu className={styles.menuDropdown}>
                                                <Link to="/was-wir-tun/#projekte"
                                                      activeClassName={styles.activeDropdownLink}
                                                      className={styles.dropDownLink}>
                                                    <Dropdown.Item
                                                        className={styles.dropDownItem}>Projekte</Dropdown.Item></Link>
                                                <Link to="/was-wir-tun/#hilfe"
                                                      activeClassName={styles.activeDropdownLink}
                                                      className={styles.dropDownLink}><Dropdown.Item
                                                    className={styles.dropDownItem}>
                                                    Hilfsmaßnahmen
                                                </Dropdown.Item>
                                                </Link>
                                                <Link to="/was-wir-tun/besonderhaut/"
                                                      activeClassName={styles.activeDropdownLink}
                                                      className={styles.dropDownLink}>
                                                    <Dropdown.Item className={styles.dropDownItem}>
                                                        BesonderHaut
                                                    </Dropdown.Item>
                                                </Link>
                                                <Link to="/was-wir-tun/foerderprogramm/"
                                                      activeClassName={styles.activeDropdownLink}
                                                      className={styles.dropDownLink}>
                                                    <Dropdown.Item className={styles.dropDownItem}>
                                                        Förderprogramm
                                                    </Dropdown.Item>
                                                </Link>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Menu.Item>
                                    <Menu.Item className={styles.mainMenuItem}><Link to="/unterstuetzen/"
                                                                                     activeClassName={styles.activeLink}
                                                                                     className={styles.link}>Unterstützen</Link></Menu.Item>
                                    <Menu.Item className={styles.mainMenuItem}><Link to="/antraege/"
                                                                                     activeClassName={styles.activeLink}
                                                                                     className={styles.link}>Anträge</Link></Menu.Item>
                                    <Menu.Item className={styles.mainMenuItem}><Link to="/ueber-uns/"
                                                                                     activeClassName={styles.activeLink}
                                                                                     className={styles.link}>Über
                                        uns</Link></Menu.Item>
                                    <Menu.Item className={styles.mainMenuItem}><Link to="/news/"
                                                                                     activeClassName={styles.activeLink}
                                                                                     className={cx(newsPartially, styles.link)}>News</Link></Menu.Item>

                                    <MediaQuery minWidth={1440} className={styles.extendedMenu} style={{display: "flex"}}>
                                        <Menu
                                            className={styles.mainMenu}
                                        >
                                        <Menu.Item className={styles.mainMenuItem}><Link to="/sponsoren/"
                                                                                         activeClassName={styles.activeLink}
                                                                                         className={styles.link}>Sponsoren & Förderer</Link></Menu.Item>
                                        <Menu.Item className={styles.mainMenuItem}><Link to="/freunde/"
                                                                                         activeClassName={styles.activeLink}
                                                                                         className={styles.link}>Freunde</Link></Menu.Item>
                                        <Menu.Item className={styles.mainMenuItem}><Link to="/stiftung/"
                                                                                         activeClassName={styles.activeLink}
                                                                                         className={styles.link}>Stiftung</Link></Menu.Item>
                                        <Menu.Item className={styles.mainMenuItem}><Link to="/video/"
                                                                                         activeClassName={styles.activeLink}
                                                                                         className={styles.link}>Video</Link></Menu.Item>
                                        </Menu>
                                    </MediaQuery>
                                    <MediaQuery minWidth={0} maxWidth={1439}>
                                        <Menu.Item className={styles.mainMenuItem}>
                                            <Dropdown direction='left' text={'mehr'} simple className={styles.link}>
                                                <Dropdown.Menu className={styles.menuDropdown}>
                                                    <Link to="/sponsoren/"
                                                          activeClassName={styles.activeDropdownLink}
                                                          className={styles.dropDownLink}>
                                                        <Dropdown.Item className={styles.dropDownItem}>Sponsoren &
                                                            Förderer</Dropdown.Item></Link>
                                                    <Link to="/freunde/"
                                                          activeClassName={styles.activeDropdownLink}
                                                          className={styles.dropDownLink}><Dropdown.Item
                                                        className={styles.dropDownItem}>
                                                        Freunde
                                                    </Dropdown.Item>
                                                    </Link>
                                                    <Link to="/stiftung/"
                                                          activeClassName={styles.activeDropdownLink}
                                                          className={styles.dropDownLink}><Dropdown.Item
                                                        className={styles.dropDownItem}>
                                                        Stiftung
                                                    </Dropdown.Item>
                                                    </Link>
                                                    <Link to="/video/"
                                                          activeClassName={styles.activeDropdownLink}
                                                          className={styles.dropDownLink}><Dropdown.Item
                                                        className={styles.dropDownItem}>
                                                        Video
                                                    </Dropdown.Item>
                                                    </Link>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Menu.Item>
                                    </MediaQuery>

                                </Menu>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </DesktopContainer>
            </div>

        );
    }
}

MainNavigation.propTypes = {
    children: PropTypes.node,

};

export default MainNavigation;